import {
  IOrderPoints,
  IOrders,
} from '../../graphql/order/models/get-orders.models';

export const getBrandsPoints = (ordersData: IOrders): IOrderPoints[] => {
  return ordersData?.consumerLensesByAuthID.length
    ? ordersData.consumerLensesByAuthID.map((orderBrand) => ({
      brandId: orderBrand.brandId,
      points: orderBrand.brand.points,
      hiddenForC2C: orderBrand.brand.hiddenForC2C,
    }))
    : [];
};
