import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './email-subscription-campaign.styles';
import imageDesktop from '../../../../../assets/images/brand-pages/email-subscription/email-subscription-gift.png';
import imageMobile from '../../../../../assets/images/brand-pages/email-subscription/email-subscription-gift-mobile.png';

export const EmailSubscriptionCampaign = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'emailSubscription.title' })}
        </title>
      </Helmet>
      <section
        className={classes.root}
        data-testid="email-subscription"
        aria-label={intl.formatMessage({ id: 'emailSubscription.ariaLabel' })}
      >
        <a
          className={classes.link}
          href={intl.formatMessage({ id: 'emailSubscription.link.href' })}
          aria-label={intl.formatMessage({ id: 'emailSubscription.link.ariaLabel' })}
          data-testid="email-subscription-link"
        >
          <img
            src={isMobile ? imageMobile : imageDesktop}
            alt=""
            data-testid="email-subscription-image"
          />
        </a>
      </section>
    </>
  );
};
