export enum InternalLinks {
  cookiePage = '/cookie-policy',
  registration = '/registration',
  login = '/login',
  resetPassword = '/reset-password',
  profilePage = '/profile',
  myLensesPage = '/my-lenses',
  myBonusesPage = '/my-bonuses',
  pointsHowToPage = '/how-to-get-redeem-points',
  programPage = '/about',
  ecpLocator = '/get-contact-lenses',
  appointment = '/appointment',
  visionProfile = '/my-acuvue-life',
  visionProfileSurvey = '/my-acuvue-life-survey',
  orderCreate = '/order-lenses',
  myOrdersPage = '/my-orders',
  home = '/',
  regGuide = '/registration-guide',
  regGuideNew = '/registration-guide-new',
  deleteAccount = '/delete-account',
  ecpLocatorExternal = '/ecp-locator-external',
  arl = '/revitalens',
  brandVision = '/facts-about-max/max-mf-vision',
  brandComfort = '/facts-about-max/max-mf-comfort',
  brandUv = '/facts-about-max/max-mf-uv',
  brandMaxComfort = '/facts-about-max/max-comfort',
  brandClarity = '/facts-about-max/max-clarity',
  brandMaxUv = '/facts-about-max/max-uv',
  winnersMax = '/facts-about-max/max-main-fact-win',
  winnersMaxMultiFocal = 'facts-about-max/max-mf-main-fact-win',
  winnersBrand = 'facts-about-the-brand/main-win',
  brand1 = '/facts-about-the-brand/brand-1',
  brandsComfort = '/facts-about-the-brand/comfort',
  brandPopularity = '/facts-about-the-brand/popularity',
  brandTrust = '/facts-about-the-brand/trust',
  brandsUv = '/facts-about-the-brand/uv',
  onlineShops = '/online-shops',
  emailSubscriptionCampaign = '/confirm-email-giftery',
}
