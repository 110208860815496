import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query GetBrandsQuery {
    brands {
      id
      parameters {
        configuration
        name
        unit
        conditional
        type
      }
      product
      hiddenForC2C
    }
  }
`;
