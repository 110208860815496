import {
  IConsumerLensesPreviewParams,
} from '../../../../../../../graphql/lenses/models/get-lenses-preview.models';
import { SolutionsBrandType } from '../../../../../../../graphql/lenses/models/get-lenses.models';
import { IBrandLoyaltyPoints } from '../my-lenses-fitted-card';

export const getPointsByBrand = (
  lensesData:  IConsumerLensesPreviewParams[],
): IBrandLoyaltyPoints[] => {
  return lensesData
    .filter((lensesBrand) => lensesBrand.brandId !== SolutionsBrandType.arl)
    .map(({ brandId, loyaltyPoints, brand }) => ({
      brandId: brandId,
      loyaltyPoints: loyaltyPoints.map(({ packSize, value }) => ({
        packSize,
        value,
      })),
      hiddenForC2C: brand.hiddenForC2C || false,
    }));
};
