import { NotificationType, FrequencyOfUsageTypes } from '../../reminders/models/reminder.models';

export interface IConsumerLenses {
  consumerLensesByAuthID: IConsumerLensesParams[];
}

export interface IConsumerLensesParams {
  id: string;
  brandId: LensesBrandType | SolutionsBrandType;
  createdManual: boolean;
  brand: IBrand;
  leftEye: IEyeParamsOption[];
  rightEye: IEyeParamsOption[];
  reminder: IReminder[];
}

export type NoveltyLabelType = {
  name: string,
  labelStartDate: string,
  labelEndDate: string,
};

export interface IBrand {
  productType: ProductType;
  fullName: string;
  labels: NoveltyLabelType[];
  points: IPoint[];
  schedule: LensesScheduleType | null;
  id: string;
  hiddenForC2C?: boolean;
}

export interface IPoint {
  packSize: number;
  acuvuePoints: number;
}

export interface IReminder {
  amountOfLenses: number;
  buyNewPackAt: string;
  firstNotification: NotificationType;
  frequencyOfUsage: FrequencyOfUsageTypes;
  secondNotification: NotificationType;
  setupAt: string;
  startWearingAt: string;
}

export interface IEyeParamsOption {
  property: EyeParamsType;
  value: string;
}

export enum EyeParamsType {
  power = 'POWER',
  baseCurve = 'BASE_CURVE',
  cylinder = 'CYLINDER',
  axis = 'AXIS',
  variant = 'VARIANT',
  addition = 'ADDITION',
}

export enum LensesBrandType {
  aoh1d = 'AOH1D',
  oneDate = '1DATE',
  oneDam = '1DAM',
  aoh1dfa = 'AOH1DfA',
  oneDamfa = '1DAMfA',
  oneDamm = '1DAMM',
  aoh = 'AOH',
  aohfa = 'AOHfA',
  aowt = 'AOwT',
  oneDadf = '1DADF',
  aohm = 'AOHM',
  aom1d = 'AOM1D',
  aomaxmf = 'AOMAXMF',
}

export enum SolutionsBrandType {
  arl = 'ARL',
}

export enum ProductType {
  lenses = 'LENSES',
  solutions = 'SOLUTIONS',
}

export enum ProductLabelNames {
  new = 'newProductLabel',
  soon = 'soonProductLabel',
  outOfSales = 'outOfSaleLabel',
}

export enum LensesScheduleType {
  daily = 'DAILY',
  reusable = 'REUSABLE',
}
