import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    background: palette.primary.dark,
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
  link: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));
