import { gql } from '@apollo/client';

export const GET_LENSES = gql`
  query GetConsumerLensesQuery {
    consumerLensesByAuthID {
      id
      brandId
      createdManual
      brand {
        id
        productType
        fullName
        schedule
        hiddenForC2C
        labels {
          name
          labelEndDate
          labelStartDate
        }
        points {
          packSize
          acuvuePoints
        }
      }
      leftEye {
        value
        property
      }
      rightEye {
        value
        property
      }
      reminder {
        amountOfLenses
        buyNewPackAt
        firstNotification
        frequencyOfUsage
        secondNotification
        setupAt
        startWearingAt
      }
    }
  }
`;
