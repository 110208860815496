import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ITitleSize, UserActivitiesList } from '../../../../../common/user-activities-list';
import { OrderCreateButton } from '../../../order/order-create-button';
import { OrdersPlaceholder } from '../../../order/placeholder';
import { IMyOrdersListProps } from './my-orders-list.models';
import { useStyles } from './my-orders-list.styles';
import { Button } from '../../../../../common/button-new';
import { InternalLinks } from '../../../../../../constants';

export const MyOrdersList = <T extends { id: string }>({
  isFitted, orders, isActionShow,
}: IMyOrdersListProps<T>): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <UserActivitiesList
      title={{
        size: ITitleSize.lg,
        label: (
          <FormattedMessage
            id="myOrdersPreview.title"
          />
        ),
      }}
      activities={orders}
      placeholder={(
        <OrdersPlaceholder
          action={(
            <OrderCreateButton
              isFitted={isFitted}
              to={InternalLinks.orderCreate}
            />
          )}
        />
      )}
      footer={!!orders.length && (
        <div className={classes.footer}>
          <OrderCreateButton
            isFitted={isFitted}
            to={InternalLinks.orderCreate}
          />
        </div>
      )}
      action={(orders.length && isActionShow) && (
        <Button
          style="outlined"
          to={InternalLinks.myOrdersPage}
          testId="my-orders-page-link"
          ariaLabel="myOrdersPreview.all.label"
          rootClasses={classes.actionButton}
        >
          <FormattedMessage
            id={isDesktop ? 'myOrdersPreview.all.label' : 'myOrdersPreview.all.mobile.label'}
          />
        </Button>
      )}
    />
  );
};
